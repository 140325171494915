import * as ReactDOM from 'react-dom';
import { Maybe } from 'dg-web-shared/lib/MaybeV2';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import * as Icons24 from 'dg-web-shared/ui/icons/Icons24';
import { SvgIcon } from 'dg-web-shared/ui/icons/SvgIcon';
import { css } from '@emotion/css';
import { Colors, Typo } from './vars';
import { rgba } from 'utils/src/Rgba.ts';
import React from 'react';

export interface OnEnterContext {
    blur: () => void;
}

export const Close = (p: { onClick: () => void }): JSX.Element => (
    <Clickable
        element="div"
        className={css({
            right: 0,
            top: 0,
            position: 'absolute',
            width: '24px',
            height: '24px',
        })}
        onClick={p.onClick}
    >
        <SvgIcon width={24} height={24} icon={Icons24.clear_b} />
    </Clickable>
);

interface Props {
    value: string;
    label: string;
    inputType: string;
    onChange: (v: any) => void;
    onBlur?: () => void;
    onFocus?: Maybe<() => void>;
    onClear?: Maybe<() => void>;
    onEnter?: (onEnterContext: OnEnterContext) => void;
    focusOnMount?: boolean;
    disabled?: boolean;
    errorText?: Maybe<string>;
    tabIndex?: Maybe<number>;
}

interface State {
    focused: boolean;
}

interface LabelProps {
    label: string;
    focused: boolean;
    hidden?: boolean;
    disabled?: boolean;
}

const Label = (p: LabelProps) => {
    const isHidden = p.hidden ?? false;
    const isDisabled = p.disabled ?? false;
    return (
        <label
            className={css({
                display: 'block',
                ...Typo.label,
                visibility: isHidden ? 'hidden' : 'visible',
                color: getLabelColor(p.focused, isDisabled),
            })}
        >
            <div className={css({ userSelect: 'none' })}>{p.label}</div>
        </label>
    );
};

function getLabelColor(isFocused: boolean, isDisabled: boolean) {
    if (isDisabled) {
        return rgba(Colors.darkBlue, 0.4);
    }
    if (isFocused) {
        return Colors.lightBlue;
    }
    return rgba(Colors.darkBlue, 0.7);
}

interface BelowContentProps {
    underline: boolean;
    focused: boolean;
    errorText: Maybe<string>;
}

export const BelowContent = (p: BelowContentProps): JSX.Element => {
    return (
        <div
            className={css({
                height:
                    p.underline && (p.focused || !!p.errorText)
                        ? '16px'
                        : '17px',
                ...Typo.caption,
                color: p.errorText ? Colors.error : 'transparent',
                borderTop: p.underline
                    ? getUnderlineStyle(p.focused, !!p.errorText)
                    : '0px none white',
            })}
            data-underline={p.underline}
            data-focused={p.focused}
            data-error={!!p.errorText}
        >
            {p.errorText}
        </div>
    );
};

function getUnderlineStyle(isFocused: boolean, isError: boolean) {
    if (isError) {
        return `2px solid ${Colors.error}`;
    }
    if (isFocused) {
        return `2px solid ${Colors.lightBlue}`;
    }
    return `1px solid ${Colors.darkBlue}`;
}

interface ContentProps {
    focused: boolean;
    empty: boolean;
    userSelectable: boolean;
    children?: React.ReactNode;
    disabled?: Maybe<boolean>;
}

export const Content = (p: ContentProps): JSX.Element => {
    const isDisabled = p.disabled ?? false;
    return (
        <div
            className={css({
                ...Typo.robotoRegular,
                paddingTop: '2px',
                color: getContentColor(p.focused, isDisabled),
                position: 'relative',
                userSelect: p.userSelectable ? 'auto' : 'none',
            })}
        >
            {p.children}
        </div>
    );
};

function getContentColor(isFocused: boolean, isDisabled: boolean) {
    if (isDisabled) {
        return rgba(Colors.darkBlue, 0.6);
    }
    if (isFocused) {
        return Colors.lightBlue;
    }
    return Colors.darkBlue;
}

interface LabeledElementProps {
    children?: React.ReactNode;
    onClick?: () => void;
}

export const LabeledElement = (p: LabeledElementProps): JSX.Element => {
    return (
        <Clickable
            element="div"
            className={css({
                position: 'relative',
                zIndex: 0,
                paddingTop: '16px',
                paddingLeft: '16px',
                paddingRight: '16px',
            })}
            onClick={p.onClick}
            disabled={!p.onClick}
        >
            {p.children}
        </Clickable>
    );
};

export class TextField extends React.Component<Props, State> {
    declare refs: {
        input: HTMLElement;
        [key: string]: React.ReactInstance;
    };

    constructor(p: Props) {
        super(p);
        this.state = { focused: false };
    }

    focusInputField(): void {
        if (this.refs.input) {
            this.refs.input.focus();
        }
    }

    blurInputField(): void {
        if (this.refs.input) {
            this.refs.input.blur();
        }
    }

    _onChange(e: any): void {
        const val = e.target.value;
        this.props.onChange(val);
    }

    componentDidMount(): void {
        if (this.props.focusOnMount) {
            this.focusInputField();
        }
    }

    UNSAFE_componentWillUpdate(nextProps: Props): void {
        /* tslint:disable:no-string-literal */
        const node: any = ReactDOM.findDOMNode(this.refs['input']);
        /*tslint:enable:no-string-literal */
        const oldLength = node.value.length; // this is the value of the field after the manual input
        const oldIdx = node.selectionStart;

        // return if there is no change, otherwise this will steal the focus (sort of) of other input fields
        // nextProps.value is the value we will ultimately display. It is the value
        // after the manual input *and* with automatic modifications made to that input (if any)
        if (
            node.value === nextProps.value ||
            nextProps.value === undefined ||
            nextProps.value === null
        ) {
            return;
        }

        node.value = nextProps.value;
        const newIdx = Math.max(0, node.value.length - oldLength + oldIdx);
        node.selectionStart = node.selectionEnd = newIdx;
    }

    hasValue(): boolean {
        return !!this.props.value;
    }

    renderHint(): JSX.Element | null {
        if (this.hasValue()) {
            return null;
        } else {
            return (
                <div
                    className={css({
                        zIndex: -1,
                        width: '100%',
                        position: 'absolute',
                        ...Typo.robotoLight,
                        fontStyle: 'italic',
                        fontSize: '20px',
                        color: rgba(Colors.darkBlue, 1),
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    })}
                >
                    {this.props.label}
                </div>
            );
        }
    }

    render() {
        return (
            <LabeledElement>
                <Label
                    focused={this.state.focused}
                    hidden={!this.hasValue()}
                    label={this.props.label}
                />
                <Content
                    userSelectable={true}
                    empty={false}
                    focused={this.state.focused}
                >
                    {this.renderHint()}
                    <input
                        className={css({
                            margin: 0,
                            padding: 0,
                            border: 'none',
                            outline: 'none',
                            verticalAlign: 'baseline',
                            whiteSpace: 'normal',
                            background: 'none',
                            lineHeight: 'inherit',
                            fontFamily: 'inherit',
                            fontSize: '20px',
                            color: 'inherit',
                            fontWeight: 'inherit',
                            display: 'block',
                            width: '100%',
                            '&:focus': {
                                outline: 0,
                            },
                        })}
                        ref="input"
                        autoCapitalize="none"
                        autoComplete="off"
                        value={this.props.value}
                        type={this.props.inputType}
                        onFocus={(): void => {
                            this.props.onFocus && this.props.onFocus();
                            this.setState({ focused: true });
                        }}
                        onBlur={() => {
                            this.setState({ focused: false });

                            if (this.props.onBlur) {
                                this.props.onBlur();
                            }
                        }}
                        onChange={(
                            e: React.SyntheticEvent<HTMLInputElement>,
                        ): void => this._onChange(e)}
                        onKeyDown={(
                            e: React.KeyboardEvent<HTMLInputElement>,
                        ): void => {
                            if (this.props.onEnter && e.key === 'Enter') {
                                this.props.onEnter({
                                    blur: () => this.blurInputField(),
                                });
                            }
                        }}
                        disabled={this.props.disabled}
                        tabIndex={this.props.tabIndex || undefined}
                    />
                    {this.props.onClear &&
                        this.props.inputType !== 'search' && (
                            <Close onClick={this.props.onClear} />
                        )}
                </Content>
                <BelowContent
                    focused={this.state.focused}
                    underline={true}
                    errorText={this.props.errorText}
                />
            </LabeledElement>
        );
    }
}
