import { texts } from '../i18n';
import { css } from '@emotion/css';
import { Colors, Typo } from './vars';
import { ButtonContainer } from './TextButton';

export function ForgottenPasswordLink(props: Props) {
    return (
        <ButtonContainer>
            <button
                type="button"
                className={buttonStyle}
                onClick={props.onClick}
            >
                {texts.forgotPassword}
            </button>
        </ButtonContainer>
    );
}

interface Props {
    onClick: () => void;
}

const buttonStyle = css`
    background none;
    border 0;
    ${Typo.robotoMedium};
    font-size: 16px;
    color: ${Colors.lightBlue};
    cursor: pointer;
`;
