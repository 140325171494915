import { css, keyframes } from '@emotion/css';
import { Colors } from './vars';

export const Spinner = (): JSX.Element => {
    const HEIGHT = 100;
    const WIDTH = HEIGHT;
    const PADDING_BOTTOM = 24;
    const dash = keyframes({
        '0%': {
            strokeDasharray: '1, 200',
            strokeDashoffset: 0,
        },
        '50%': {
            strokeDasharray: '89, 200',
            strokeDashoffset: -35,
        },
        '100%': {
            strokeDasharray: '89, 200',
            strokeDashoffset: -124,
        },
    });
    const rotate = keyframes({
        '100%': {
            transform: 'rotate(360deg)',
        },
    });

    return (
        <div
            className={css({
                position: 'absolute',
                width: `${WIDTH}px`,
                height: `${HEIGHT}px`,
                top: '50%',
                left: '50%',
                marginLeft: `${-(WIDTH / 2)}px`,
                marginTop: `${-HEIGHT - PADDING_BOTTOM}px`,
            })}
        >
            <svg
                className={css({
                    animation: `${rotate} 2s linear infinite`,
                    width: `${WIDTH}px`,
                    height: `${HEIGHT}px`,
                    position: 'relative',
                })}
            >
                <circle
                    className={css({
                        strokeDasharray: '1, 200',
                        strokeDashoffset: 0,
                        stroke: Colors.lightBlue,
                        animation: `${dash} 1.5s ease-in-out infinite`,
                        strokeLinecap: 'round',
                    })}
                    cx={50}
                    cy={50}
                    r={20}
                    fill="none"
                    strokeWidth={3}
                    strokeMiterlimit={'10'}
                />
            </svg>
        </div>
    );
};
