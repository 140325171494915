import { css } from '@emotion/css';
import { selectState, Store, Updater } from 'dg-web-shared/lib/Flux';
import { request, Response } from '../AsyncRequest';
import { enforcementGet, Env, LegacyRequest } from '../Http';
import { texts } from '../i18n';
import * as AuthTokenState from '../state/AuthTokenState';
import * as LoginState from '../state/LoginState';
import { LogoAndName } from './LogoAndName';
import { ResetPasswordDeck } from './ResetPasswordDeck';
import { Spinner } from './Spinner';
import { ButtonContainer, TextButton } from './TextButton';
import { TextField } from './TextField';
import { Colors, Typo } from './vars';
import { b64EncodeUnicode } from 'dg-web-shared/lib/Http';

/* gotta be a little hacky to know if demo or not in response */
const loginMaker = (env: Env) => {
    return request({
        req: (args?: {
            username: string;
            password: string;
            env: Env;
        }): LegacyRequest => {
            return enforcementGet('/auth-token', args!.env).set(
                'Authorization',
                b64EncodeUnicode(`${args!.username}:${args!.password}`),
            ) as LegacyRequest;
        },
        useAuthToken: false,
        requestWrite: (store: Store, res: Response): string => {
            LoginState.Request.setResponse(store, res);
            if (res.statusCode.cls.success) {
                AuthTokenState.stateWrite(store, {
                    authToken: res.body.token,
                    env,
                });
            }
            return 'login';
        },
    });
};

export const Login = selectState<
    { update: Updater },
    {
        form: LoginState.Form.State;
        request: LoginState.Request.State;
        authToken: AuthTokenState.State;
    }
>(
    (store: Store) => {
        return {
            form: LoginState.Form.get(store),
            request: LoginState.Request.get(store),
            authToken: AuthTokenState.get(store),
        };
    },
    p => {
        if (p.authToken.authToken === null) {
            return <Spinner />;
        }
        const newState = {
            username: p.form.username,
            password: p.form.password,
        };
        return (
            <div
                className={css({
                    background: Colors.greyBackground,
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                })}
            >
                <LogoAndName />
                <div
                    className={css({
                        background: Colors.greyBackground,
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        paddingTop: '8px',
                        flex: '1 0 auto',
                    })}
                >
                    <div>
                        <TextField
                            inputType="text"
                            label={texts.username}
                            value={p.form.username}
                            onChange={(username: string) =>
                                p.update(store =>
                                    LoginState.Form.stateWrite(store, {
                                        username,
                                    }),
                                )
                            }
                        />
                    </div>
                    <div>
                        <TextField
                            inputType="password"
                            label={texts.password}
                            value={p.form.password}
                            onChange={(password: string) =>
                                p.update(store =>
                                    LoginState.Form.stateWrite(store, {
                                        password,
                                    }),
                                )
                            }
                            onEnter={() =>
                                p.update(loginMaker('prod'), {
                                    env: 'prod',
                                    ...newState,
                                })
                            }
                        />
                    </div>
                    {p.request.statusCode.cls.error && (
                        <div
                            className={css({
                                ...Typo.robotoLight,
                                paddingLeft: '16px',
                                color: Colors.red,
                            })}
                        >
                            {texts.wrongPw}
                        </div>
                    )}
                    {!p.form.specialEnv && (
                        <ButtonContainer>
                            <TextButton
                                disabled={!p.form.username || !p.form.password}
                                onClick={() =>
                                    p.update(loginMaker('prod'), {
                                        env: 'prod',
                                        ...newState,
                                    })
                                }
                                label={texts.login}
                            />
                        </ButtonContainer>
                    )}
                    {p.form.specialEnv && (
                        <ButtonContainer>
                            <TextButton
                                disabled={!p.form.username || !p.form.password}
                                onClick={() =>
                                    p.update(
                                        loginMaker(
                                            p.form.specialEnv as
                                                | 'test'
                                                | 'demo',
                                        ),
                                        {
                                            env: p.form.specialEnv as
                                                | 'test'
                                                | 'demo',
                                            ...newState,
                                        },
                                    )
                                }
                                label={texts.login + ' ' + p.form.specialEnv}
                            />
                        </ButtonContainer>
                    )}
                    <img
                        className={css({
                            width: '150px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        })}
                    />
                </div>
                <footer
                    className={css({ flexShrink: 0, marginBottom: '12px' })}
                >
                    <ResetPasswordDeck suggestedUsername={p.form.username} />
                </footer>

                <div id="slidein" />
            </div>
        );
    },
);
