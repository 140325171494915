export const LPR_DISCLAIMER_KEY = 'parkingcheckLprDisclaimerAccepted';

export function getDisclaimerItems() {
    const results = [];
    for (let i = 0; i < window.localStorage.length; i++) {
        const key = window.localStorage.key(i)!; // we're iterating over the array, so it exists
        if (key.startsWith(LPR_DISCLAIMER_KEY)) {
            results.push({ key, value: window.localStorage.getItem(key)! });
        }
    }

    return results;
}

export function saveDisclaimerItems(
    keys: Array<{ key: string; value: string }>,
) {
    keys.forEach(({ key, value }) => {
        localStorage.setItem(key, value);
    });
}
