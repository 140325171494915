import * as superagent from 'superagent';
import { Store, Update } from 'dg-web-shared/lib/Flux';
import {
    makePendingResponse,
    makeTimeoutResponse,
    Response,
    SuperagentResponse,
} from 'dg-web-shared/lib/HttpResponse';
import * as LocalStorage from 'dg-web-shared/lib/LocalStorage';
import { getDisclaimerItems, saveDisclaimerItems } from './LprDisclaimerFlags';
import * as AuthTokenState from './state/AuthTokenState';
import { LegacyRequest } from './Http.ts';

export type { Response } from 'dg-web-shared/lib/HttpResponse';

export interface RequestWrite {
    (store: Store, res: Response): string;
}

export function makeResponse(
    err: any,
    req: LegacyRequest,
    sRes: superagent.Response<any>,
): Response {
    let res: Response;

    if (err && !sRes) {
        res = makeTimeoutResponse(req);
    } else {
        res = new SuperagentResponse(req, sRes);
    }
    return res;
}

export function globalRequestBehaviorUpdate(
    store: Store,
    res: Response,
    _requestUpdate: RequestWrite,
): void {
    if (res.statusCode.unauthorized) {
        // the disclaimer flags are bound to the device, therefore
        // they must be preserved to ensure the correct 1-time behavior
        const disclaimerItems = getDisclaimerItems();
        LocalStorage.clear();
        saveDisclaimerItems(disclaimerItems);
        AuthTokenState.reset(store);
    }
}

/**
 * @deprecated
 */
export function request<V>(options: {
    req: (args?: V | undefined) => LegacyRequest;
    useAuthToken: boolean;
    requestWrite: RequestWrite;
}): Update<V> {
    return (store: Store, args?: V): string => {
        const req = options.req(args);

        if (options.useAuthToken) {
            req.set(
                'Authorization',
                `token ${AuthTokenState.get(store).authToken}`,
            );
        }

        const pendingName =
            options.requestWrite(store, makePendingResponse(req)) +
            '-requestPending-log-ignore';
        req.end((err: any, superagentRes: superagent.Response<any>) => {
            const res = makeResponse(err, req, superagentRes);
            globalRequestBehaviorUpdate(store, res, options.requestWrite);
            store.update(
                store =>
                    options.requestWrite(store, res) +
                    '-requestReturned-log-ignore',
            );
        });
        return pendingName;
    };
}
