import * as Flux from 'dg-web-shared/lib/Flux';
import { Geolocation, Location } from 'dg-web-shared/common/utils/Geolocation';

export namespace GeolocationState {
    export interface State {
        enabled: boolean;
        positionError: boolean;
        currentLocation: Location | null;
        watchId: number | null;
    }

    export const { set, get, reset, stateWrite } = Flux.generateState<State>(
        'common/state/GeolocationState.GeolocationState',
        {
            enabled: false,
            positionError: false,
            currentLocation: null,
            watchId: null,
        },
        (store, state) => {
            if (
                state.enabled &&
                Geolocation.hasGeolocation() &&
                state.watchId == null &&
                !state.positionError
            ) {
                const watchId = Geolocation.watchPosition(
                    pos => {
                        store.update(store => setCurrentPosition(store, pos));
                    },
                    err => store.update(store => writeError(store, err)),
                );
                stateWrite(store, {
                    watchId: watchId,
                });
            }
        },
    );

    export const setCoordinatesIfChanged = (
        store: Flux.Store,
        location: Location,
    ): string => {
        const currentPosition = get(store).currentLocation;
        if (
            currentPosition === null ||
            Math.abs(Geolocation.distance(location, currentPosition)) > 50
        ) {
            stateWrite(store, {
                positionError: false,
                currentLocation: {
                    latitude: location.latitude,
                    longitude: location.longitude,
                    accuracy: location.accuracy,
                },
            });
        }
        return 'GeolocationState.setCoordinatesIfChanged';
    };

    export const setError = (store: Flux.Store): string => {
        stateWrite(store, {
            positionError: true,
            currentLocation: null,
        });
        return 'GeolocationState.setError';
    };

    const setCurrentPosition = (
        store: Flux.Store,
        pos: GeolocationPosition,
    ) => {
        const currentPosition = get(store).currentLocation;
        if (
            currentPosition === null ||
            Math.abs(Geolocation.distance(pos.coords, currentPosition)) > 25
        ) {
            stateWrite(store, {
                positionError: false,
                currentLocation: {
                    latitude: pos.coords.latitude,
                    longitude: pos.coords.longitude,
                },
            });
        }
        return 'GeolocationState.setCurrentPosition';
    };

    export const writeError = (
        store: Flux.Store,
        err: GeolocationPositionError,
    ) => {
        const watchId = get(store).watchId;
        if (watchId && err.code < 3) {
            Geolocation.clearWatch(watchId);
            return stateWrite(store, {
                positionError: true,
                currentLocation: null,
            });
        } else {
            return stateWrite(store, {
                currentLocation: null,
            });
        }
    };
}
