/**
 * @deprecated
 */
export enum Language {
    DE = 'de',
    FR = 'fr',
    IT = 'it',
}
/**
 * @deprecated
 */
export const language = (): Language => {
    const locale = navigator.language;
    if (!locale) {
        return Language.DE;
    }
    if (locale.slice(0, 2) === 'fr') {
        return Language.FR;
    }
    if (locale.slice(0, 2) === 'it') {
        return Language.IT;
    } else {
        return Language.DE;
    }
};

/**
 * @deprecated
 */
type Message = { [key in Language]: string };
/**
 * @deprecated
 */
type JsxMessage = { [key in Language]: JSX.Element };
/**
 * @deprecated
 */
export const Localized: React.FC<Message | JsxMessage> = props => {
    return <>{props[language()]}</>;
};
/**
 * @deprecated
 */
const transl: { [idx: string]: Transl } = {
    de: {
        accept: 'Akzeptieren',
        alreadyPaired: `Einer anderen Zone bereits zugewiesen.`,
        alreadyPairedCurrentZone: 'Dieser Zone bereits zugewiesen.',
        alreadyPairedOtherOperator: 'QR-Code gehört einem anderen Betreiber.',
        alreadyPairedPermit: 'QR-Code ist schon einer Bewilligung zugewiesen.',
        attention: 'ACHTUNG',
        cancel: 'Abbrechen',
        changePassword: 'Passwort ändern',
        close: 'Schliessen',
        confirm: 'Bestätigen',
        confirmPair: `QR-Code mit der gewählten Zone verknüpfen?`,
        confirmPassword: 'Passwort bestätigen',
        enforce: 'Kontrollieren',
        enforceDataError:
            'Abfrage konnte nicht durchgeführt werden. Das Gerät hat vermutlich keinen Internetzugriff',
        enforcementTime: 'Kontrollzeit:',
        forgotPassword: 'Passwort vergessen?',
        helpLprAT: 'Österreich',
        helpLprCH: 'Schweiz',
        helpLprDE: 'Deutschland',
        helpLprExpl: 'Die Kamera auf das Kennzeichen ausrichten.',
        helpLprFL: 'Liechtenstein',
        helpLprFR: 'Frankreich',
        helpLprHeader: 'Kennzeichen erkennen',
        helpLprIT: 'Italien',
        helpLprSupportedCountries: 'Unterstützte Länder:',
        helpLprUeCountries: 'EU Länder',
        helpLprExcludedUeCountries: '(ausser GB, CY, IE, MT)',
        helpRfidExpl: 'Das Smartphone an die Parkingpay-Vignette halten.',
        helpRfidheader: 'Vignette scannen',
        helpTextExpl:
            'Das vollständige Kennzeichen per Tastatur eingeben (ohne Leerzeichen oder andere Sonderzeichen).',
        helpTextHeader: 'Kennzeichen eingeben',
        lprCanFailPart1:
            'Das Kennzeichenerkennungssystem, das Sie gleich verwenden werden, ist zwar zuverlässig, kann aber keine 100%ige Genauigkeit garantieren.',
        lprCanFailPart2: 'Aus diesem Grund',
        lprCanFailPart3:
            'muß der Kontrolleur das automatisch erkannte Kennzeichen immer mit dem tatsächlichen Kennzeichen vergleichen,',
        lprCanFailPart4: 'um Fehler zu vermeiden.',
        lprCanFailTitle: 'WICHTIG',
        location: 'Ort',
        login: 'Anmelden',
        logout: 'Abmelden',
        masterDataError:
            'Daten können nicht geladen werden. Das Gerät hat vermutlich kein Internetzugriff.',
        newPassword: 'Neues Passwort',
        overrideQrCode: 'Überschreiben',
        overrideQrCodeInfo: `Die aktuelle Zone wird mit dieser Zone überschrieben!!!`,
        pairQrCode: 'Zuweisen',
        pairingSuccess: 'QR-Code erfolgreich zugewiesen.',
        password: 'Passwort',
        passwordChangeFailed:
            'Das Passwort konnte nicht geändert werden. Bitte versuchen Sie später nochmals.',
        passwordChangeSucceeded: 'Das neue Passwort wurde gespeichert.',
        passwordFieldsDoNotMatch: 'Passwörter stimmen nicht überein.',
        passwordIsTooShort: minLength =>
            `Das Passwort ist zu kurz. Es muss midestens ${minLength} Zeichen lang sein.`,
        passwordResetRequestFailed: httpStatus =>
            `Die Anfrage konnte nicht übermittelt werden (HTTP ${httpStatus}). Bitte versuchen Sie später nochmals.`,
        passwordResetRequestFailedNoEmailAdmin: username =>
            `Wir können den Vorgang zum Zurücksetzen des Kennworts nicht starten, da beim Benutzer "${username}" keine E-Mail-Adresse hintergelegt ist. Bitte wenden Sie sich an unseren Parkingportal-Helpdesk.`,
        passwordResetRequestFailedNoEmailUser: (
            username: string,
            adminName: string,
        ) =>
            `Wir können den Vorgang zum Zurücksetzen des Kennworts nicht starten, da beim Benutzer "${username}" keine E-Mail-Adresse hintergelegt ist. Bitte wenden Sie sich an euren Administrator (${adminName}).`,
        passwordResetRequestSucceeded: (username: string) =>
            `Wir haben Ihre Anfrage erhalten; falls der Benutzername "${username}" existiert, werden wir an die zugehörige E-Mail-Adresse einen Link senden.`,
        qrCode: 'QR-Code',
        reload: 'Neu laden',
        requiredField: 'Pflichtfeld',
        resetPassword: 'Passwort zurücksetzen',
        save: 'Speichern',
        searchHint: 'Kennzeichen',
        submit: 'Bestätigen',
        unknownQrCode: 'QR-Code unbekannt.',
        unpaired: 'Noch nicht zugewiesen.',
        username: 'Benutzername',
        wrongPw: 'Benutzername oder Passwort falsch.',
        zone: 'Zone',
    },
    fr: {
        accept: "J'accepte",
        alreadyPaired: `Déjà assigné à une autre zone.`,
        alreadyPairedCurrentZone: 'Déjà assigné à cette zone.',
        alreadyPairedOtherOperator:
            "Ce code QR est attribué à une zone d'un autre exploitant.",
        alreadyPairedPermit: 'Ce code QR est attribué à une autorisation.',
        attention: 'ATTENTION',
        cancel: 'Annuler',
        changePassword: 'Changer mot de passe',
        close: 'Fermer',
        confirm: 'Confirmer',
        confirmPair: `Assigner le code QR à la zone sélectionnée?`,
        confirmPassword: 'Confirmer le mot de passe',
        enforce: 'contrôler',
        enforceDataError:
            "La requête n'a pas pu être envoyée. L'appareil n'a probablement pas accès à Internet",
        enforcementTime: 'Heure du contrôle:',
        forgotPassword: 'Mot de passe oublié?',
        helpLprAT: 'Autriche',
        helpLprCH: 'Suisse',
        helpLprDE: 'Allemagne',
        helpLprExpl: "Dirigez la caméra vers la plaque d'immatriculation.",
        helpLprFL: 'Liechtenstein',
        helpLprFR: 'France',
        helpLprHeader: "Reconnaître l'immatriculation",
        helpLprIT: 'Italie',
        helpLprSupportedCountries: 'Pays pris en charge:',
        helpLprUeCountries: "Pays de l'Union Européenne",
        helpLprExcludedUeCountries: '(sauf GB, CY, IE, MT)',
        helpRfidExpl: 'Approcher le smartphone à la vignette Parkingpay.',
        helpRfidheader: 'Scanner la vignette',
        helpTextExpl:
            'En utilisant le clavier, saisir entièrement le numero de la plaque (sans espaces ni autres caractères spéciaux).',
        helpTextHeader: "Saisir l'immatriculation",
        lprCanFailPart1:
            'Le système de reconnaissance des plaques que vous vous apprêtez à utiliser est fiable, mais ne peut garantir une précision de 100%.',
        lprCanFailPart2: 'Pour cette raison,',
        lprCanFailPart3:
            "le contrôleur doit toujours comparer la plaque d'immatriculation reconnue automatiquement avec la plaque d'immatriculation réelle",
        lprCanFailPart4: "afin d'éviter toute erreur.",
        lprCanFailTitle: 'Important',
        location: 'Localité',
        login: 'Se connecter',
        logout: 'Déconnecter',
        masterDataError:
            "La requête n'a pas pu être envoyée. L'appareil n'a probablement pas accès à Internet",
        newPassword: 'Nouveau mot de passe',
        overrideQrCode: 'Remplacer',
        overrideQrCodeInfo: `La zone actuellement connectée sera remplacée par la zone suivante!!!`,
        pairQrCode: 'Assigner',
        pairingSuccess: 'Code QR attribué avec succès.',
        password: 'Mot de passe',
        passwordChangeFailed:
            "Le mot de passe n'a pas pu être changé. Veuillez réessayer plus tard.",
        passwordChangeSucceeded: 'Le nouveau mot de passe a été enregistré.',
        passwordFieldsDoNotMatch: 'Les mots de passe ne correspondent pas.',
        passwordIsTooShort: minLength =>
            `Le mot de passe est trop court. Il doit contenir au moins ${minLength} caractères.`,
        passwordResetRequestFailed: httpStatus =>
            `La requête n'a pas pu être envoyée (HTTP ${httpStatus}). Veuillez réessayer plus tard.`,
        passwordResetRequestFailedNoEmailAdmin: username =>
            `Nous ne pouvons pas démarrer le processus de réinitialisation du mot de passe car pour l'utilisateur "${username}" il y a aucune adresse e-mail configurée. Veuillez contacter notre helpdesk Parkingportal.`,
        passwordResetRequestFailedNoEmailUser: (username, adminName) =>
            `Nous ne pouvons pas démarrer le processus de réinitialisation du mot de passe car pour l'utilisateur "${username}" il y a aucune adresse e-mail configurée. Veuillez contacter votre administrateur (${adminName}).`,
        passwordResetRequestSucceeded: username =>
            `Nous avons reçu votre demande. Si le nom d'utilisateur "${username}" existe, nous allons vous envoyer un lien à l'adresse e-mail associée.`,
        qrCode: 'Code QR',
        reload: 'Recharger',
        requiredField: 'Champ obligatoire',
        resetPassword: 'Réinitialiser MDP',
        save: 'Sauvegarder',
        searchHint: 'Immatriculation',
        submit: 'Confirmer',
        unknownQrCode: 'Code QR inconnu.',
        unpaired: 'Pas encore attribué.',
        username: "Nom d'utilisateur",
        wrongPw: "Mauvais nom d'utilisateur ou mot de passe.",
        zone: 'Zone',
    },
    it: {
        accept: 'Accetta',
        alreadyPaired: `Già assegnato ad un'altra zona.`,
        alreadyPairedCurrentZone: 'Già assegnato a questa zona.',
        alreadyPairedOtherOperator:
            'Questo codice QR é assegnato a una zona di un altro gestore.',
        alreadyPairedPermit:
            'Questo codice QR é assegnato a un autorizzazione.',
        attention: 'ATTENZIONE',
        cancel: 'Annulla',
        changePassword: 'Modifica password',
        close: 'Chiudere',
        confirm: 'Conferma',
        confirmPair: `Assegnare il codice QR alla zona selezionata?`,
        confirmPassword: 'Conferma password',
        enforce: 'verificare',
        enforceDataError:
            'La richiesta non ha potuto essere inviata. Il dispositivo probabilmente non ha accesso a Internet.',
        enforcementTime: 'Ora del controllo:',
        forgotPassword: 'Password dimenticata?',
        helpLprAT: 'Austria',
        helpLprCH: 'Svizzera',
        helpLprDE: 'Germania',
        helpLprExpl: 'Inquadrare la targa con la videocamera.',
        helpLprFL: 'Liechtenstein',
        helpLprFR: 'Francia',
        helpLprHeader: 'Riconoscimento targa',
        helpLprIT: 'Italia',
        helpLprSupportedCountries: 'Paesi supportati:',
        helpLprUeCountries: "Paesi dell'Unione Europea",
        helpLprExcludedUeCountries: '(tranne GB, CY, IE, MT)',
        helpRfidExpl: 'Avvicinare lo smartphone alla vignetta Parkingpay.',
        helpRfidheader: 'Scansione vignetta',
        helpTextExpl:
            'Inserire tramite la tastiera il numero di targa completo (senza spazi o altri caratteri speciali).',
        helpTextHeader: 'Inserimento targa',
        lprCanFailPart1:
            'Il sistema di riconoscimento della targa che vi apprestate ad utilizzare è sì affidabile, ma non può garantire un’accuratezza del 100%.',
        lprCanFailPart2: 'Per questo motivo',
        lprCanFailPart3:
            'il controllore è sempre tenuto a confrontare la targa riconosciuta automaticamente con quella reale,',
        lprCanFailPart4: 'al fine di evitare eventuali errori.',
        lprCanFailTitle: 'Importante',
        location: 'Località',
        login: 'Accedi',
        logout: 'Esci',
        masterDataError:
            'La richiesta non ha potuto essere inviata. Il dispositivo probabilmente non ha accesso a Internet.',
        newPassword: 'Nuova password',
        overrideQrCode: 'Sostituisci',
        overrideQrCodeInfo: `La zona attualmente collegata verrà sostituita dalla seguente zona!!!`,
        pairQrCode: 'Assegna',
        pairingSuccess: 'Codice QR assegnato con successo.',
        password: 'Password',
        passwordChangeFailed:
            'Non è stato possibile modificare la password. Vogliate riprovare più tardi.',
        passwordChangeSucceeded: 'La nuova password è stata salvata.',
        passwordFieldsDoNotMatch: 'Le due password inserite non corrispondono',
        passwordIsTooShort: minLength =>
            `La password è troppo corta. Deve contenere almeno ${minLength} caratteri.`,
        passwordResetRequestFailed: httpStatus =>
            `La richiesta non ha potuto essere inviata (HTTP ${httpStatus}). Vogliate riprovare più tardi.`,
        passwordResetRequestFailedNoEmailAdmin: (username: string) =>
            `Non è possibile avviare la procedura di reimpostazione della password perché per l'utente "${username}" non è configurato alcun indirizzo e-mail. Vogliate di contattare il nostro helpdesk Parkingportal.`,
        passwordResetRequestFailedNoEmailUser: (
            username: string,
            adminName: string,
        ) =>
            `Non è possibile avviare la procedura di reimpostazione della password perché per l'utente "${username}" non è configurato alcun indirizzo e-mail. Vogliate di contattare il vostro amministratore (${adminName}).`,
        passwordResetRequestSucceeded: (username: string) =>
            `Abbiamo ricevuto la sua richiesta; se il nome utente "${username}" esiste, invieremo un link all'indirizzo e-mail associato.`,
        qrCode: 'Codice QR',
        reload: 'Ricarica',
        requiredField: 'Campo obbligatorio',
        resetPassword: 'Reimposta password',
        save: 'Salva',
        searchHint: 'Targa',
        submit: 'Conferma',
        unknownQrCode: 'Codice QR sconosciuto.',
        unpaired: 'Non ancora assegnato.',
        username: 'Nome utente',
        wrongPw: 'Nome utente o password non corretti.',
        zone: 'Zona',
    },
};

interface Transl {
    accept: string;
    alreadyPaired: string;
    alreadyPairedCurrentZone: string;
    alreadyPairedOtherOperator: string;
    alreadyPairedPermit: string;
    attention: string;
    cancel: string;
    changePassword: string;
    close: string;
    confirm: string;
    confirmPair: string;
    confirmPassword: string;
    enforce: string;
    enforceDataError: string;
    enforcementTime: string;
    forgotPassword: string;
    helpLprAT: string;
    helpLprCH: string;
    helpLprDE: string;
    helpLprExpl: string;
    helpLprFL: string;
    helpLprFR: string;
    helpLprHeader: string;
    helpLprIT: string;
    helpLprSupportedCountries: string;
    helpLprUeCountries: string;
    helpLprExcludedUeCountries: string;
    helpRfidExpl: string;
    helpRfidheader: string;
    helpTextExpl: string;
    helpTextHeader: string;
    lprCanFailTitle: string;
    lprCanFailPart1: string;
    lprCanFailPart2: string;
    lprCanFailPart3: string;
    lprCanFailPart4: string;
    location: string;
    login: string;
    logout: string;
    masterDataError: string;
    newPassword: string;
    overrideQrCode: string;
    overrideQrCodeInfo: string;
    pairQrCode: string;
    pairingSuccess: string;
    password: string;
    passwordChangeFailed: string;
    passwordChangeSucceeded: string;
    passwordFieldsDoNotMatch: string;
    passwordIsTooShort: (minLength: number) => string;
    passwordResetRequestFailed: (httpStatus: number) => string;
    passwordResetRequestFailedNoEmailAdmin: (username: string) => string;
    passwordResetRequestFailedNoEmailUser: (
        username: string,
        adminName: string,
    ) => string;
    passwordResetRequestSucceeded: (username: string) => string;
    qrCode: string;
    reload: string;
    requiredField: string;
    resetPassword: string;
    save: string;
    searchHint: string;
    submit: string;
    unknownQrCode: string;
    unpaired: string;
    username: string;
    wrongPw: string;
    zone: string;
}

export const texts = transl[language()];
