import { css } from '@emotion/css';
import { Colors, Typo } from './vars';

import iconLogo from '../../assets/logo-icon.svg';

export const LogoAndName = (): JSX.Element => {
    return (
        <div
            className={css({
                background: Colors.darkBlue,
                textAlign: 'center',
                paddingTop: 0,
            })}
        >
            <div
                className={css({
                    display: 'flex',
                    padding: '16px',
                    alignItems: 'center',
                })}
            >
                <img
                    className={css({ width: 32, height: 32 })}
                    src={iconLogo}
                />
                <div
                    className={css({
                        color: Colors.white,
                        ...Typo.robotoLight,
                        marginLeft: '16px',
                        fontSize: 24,
                        letterSpacing: '0.04em',
                    })}
                >
                    Parkingcheck
                </div>
            </div>
        </div>
    );
};
