import * as Flux from 'dg-web-shared/lib/Flux';
import * as LocalStorage from 'dg-web-shared/lib/LocalStorage';
import { Env } from '../Http';

export interface State {
    authToken: string | null;
    env: Env;
}

const s = Flux.generateState<State>(
    'AuthTokenState',
    LocalStorage.getSerializableItem<State>(
        LocalStorage.Items.enforcementAppToken,
    ) || {
        authToken: '',
        env: 'prod',
    },
);

export const reset = s.reset;

export const get = (store: Flux.Store): State => {
    const state = s.get(store);
    return state;
};
export const stateWrite = (
    store: Flux.Store,
    state: Partial<State>,
): string => {
    const name = s.stateWrite(store, state);
    LocalStorage.setSerializableItem(
        LocalStorage.Items.enforcementAppToken,
        get(store),
    );
    return name;
};
