import { css } from '@emotion/css';
import { selectState, Store } from 'dg-web-shared/lib/Flux';
import { texts } from '../i18n';
import { Context, ResultNamespace, Version } from '../state/EnforcementState';
import { Spinner } from './Spinner';
import { LoginSlideInTrigger } from './UserSlideIn';
import { EnforcementResult } from 'enforcement-result/src/EnforcementResult.tsx';

interface ResultState {
    result: ResultNamespace.State;
}

interface ResultProps {
    context: Context.State;
    username: string;
    authToken: string;
}

export const Result: React.ComponentType<ResultProps> = selectState<
    ResultProps,
    ResultState
>(
    (store: Store, props: ResultProps) => {
        const context = {
            version: Version.get(store),
            ...props.context,
        };
        return {
            result: ResultNamespace.get(store, context),
        };
    },
    p => {
        if (p.result.status === 'pending') {
            return <Spinner />;
        }

        if (!p.result.data) {
            return (
                <div>
                    <div>{texts.enforceDataError}</div>
                </div>
            );
        }

        return (
            <div
                className={css({
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                })}
            >
                <EnforcementResult result={p.result.data} />
                <LoginSlideInTrigger
                    update={p.update}
                    username={p.username}
                    authToken={p.authToken}
                />
            </div>
        );
    },
);
