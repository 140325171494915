import { selectState, Store, Updater } from 'dg-web-shared/lib/Flux';
import * as EnforcementState from '../state/EnforcementState';
import * as MasterDataState from '../state/MasterDataState';
import * as AuthTokenState from '../state/AuthTokenState';
import { Result } from './Result';
import { texts } from '../i18n';
import { EnforcementConfig } from './EnforcementConfig';
import { ButtonContainer, TextButton } from './TextButton';
import { Instruction } from './Instruction';
import { Spinner } from './Spinner';
import { logout } from './UserSlideIn';
import { Env } from '../Http';
import { QrCodeScanner } from './TwintQrCodeScanner';
import { GeolocationState } from '../state/GeolocationState';
import { css } from '@emotion/css';
import { Colors } from './vars';
import { AlertBox } from './Modals';
import { LPR_DISCLAIMER_KEY } from '../LprDisclaimerFlags';
import { useEffect, useState } from 'react';

interface EnforcementState {
    masterData: MasterDataState.State;
    form: EnforcementState.Form.State;
    layout: EnforcementState.Layout.State;
    context: EnforcementState.Context.State | null;
    authToken: AuthTokenState.State;
}

interface EnforcementProps {
    env: Env;
}

export const Enforcement = selectState<EnforcementProps, EnforcementState>(
    (store: Store, props: EnforcementProps): EnforcementState => {
        const location = GeolocationState.get(store).currentLocation;
        const layout = EnforcementState.Layout.get(store);
        return {
            masterData: MasterDataState.get(store, {
                env: props.env,
                location,
                twintPairing: layout.mode === 'twint-qr-code',
            }),
            layout,
            form: EnforcementState.Form.get(store),
            context: EnforcementState.Context.get(store),
            authToken: AuthTokenState.get(store),
        };
    },
    p => {
        if (p.masterData.status === 'pending' && !p.masterData.data) {
            return <Spinner />;
        }

        if (!p.masterData.data) {
            return (
                <ButtonContainer>
                    <TextButton
                        label={texts.reload}
                        onClick={() => p.update(logout)}
                    />
                </ButtonContainer>
            );
        } else {
            const activeZone = p.masterData.data.zones.filter(
                it => p.form.zoneId === it.id,
            )[0];
            return (
                <div
                    className={css({
                        position: 'relative',
                        height: '100%',
                        overflow: 'hidden',
                    })}
                >
                    <div
                        className={css({
                            position: 'absolute',
                            top: p.layout.mode === 'lpr' ? '25%' : 0, // If you change this percentage release a new app
                            bottom: 0,
                            left: 0,
                            right: 0,
                            background: Colors.greyBackground,
                        })}
                    >
                        <div
                            className={css({
                                position: 'relative',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'hidden',
                                background: 'white',
                            })}
                        >
                            <div
                                className={css({
                                    display: 'flex',
                                    flexGrow: 1,
                                    overflow: 'scroll',
                                })}
                            >
                                {p.layout.mode === 'twint-qr-code' ? (
                                    <QrCodeScanner
                                        selectedZone={p.form.zoneId}
                                        zoneName={`${activeZone.extZoneCode} ${activeZone.name} | ${activeZone.city}`}
                                    />
                                ) : (
                                    <EnforcementData {...p} />
                                )}
                            </div>
                            <EnforcementConfig
                                form={p.form}
                                layout={p.layout}
                                masterData={p.masterData.data}
                                context={p.context}
                                update={p.update}
                                env={p.env}
                            />
                        </div>
                    </div>
                    <div id="slidein" />
                    <div id="modal" />
                </div>
            );
        }
    },
);

const EnforcementData = (
    p: EnforcementProps & EnforcementState & { update: Updater },
) => {
    const [hasAcceptedLPRDisclaimer, setHasAcceptedLPRDisclaimer] =
        useState(false);

    const localStorageDisclaimerKey = `${LPR_DISCLAIMER_KEY}-${p.masterData.data?.userName}`;

    useEffect(() => {
        if (p.masterData.data) {
            setHasAcceptedLPRDisclaimer(
                localStorage.getItem(localStorageDisclaimerKey) === 'true',
            );
        }
    }, [p.masterData.data]);

    if (!p.masterData.data) {
        return null;
    }

    const usernameWithEnv =
        p.masterData.data &&
        p.masterData.data.userName + (p.env !== 'prod' ? ` (${p.env})` : '');

    const authToken = p.authToken.authToken;

    function acceptDisclaimer() {
        localStorage.setItem(localStorageDisclaimerKey, 'true');
        setHasAcceptedLPRDisclaimer(true);
    }

    if (!authToken) {
        return null;
    }

    return (
        <>
            {!hasAcceptedLPRDisclaimer && p.layout.mode === 'lpr' && (
                <AlertBox
                    confirmColor="blue"
                    confirmLabel={texts.accept}
                    onConfirm={acceptDisclaimer}
                >
                    <p>
                        <strong>{texts.lprCanFailTitle}</strong>
                    </p>
                    <p>{texts.lprCanFailPart1}</p>
                    <p>
                        {texts.lprCanFailPart2}{' '}
                        <strong>{texts.lprCanFailPart3}</strong>{' '}
                        {texts.lprCanFailPart4}
                    </p>
                </AlertBox>
            )}
            {p.context ? (
                <Result
                    context={p.context}
                    username={usernameWithEnv}
                    authToken={authToken}
                />
            ) : (
                <Instruction
                    layout={p.layout}
                    update={p.update}
                    username={usernameWithEnv}
                    authToken={authToken}
                />
            )}
        </>
    );
};
