import { css } from '@emotion/css';
import {
    generateState,
    selectState,
    Store,
    Updater,
} from 'dg-web-shared/lib/Flux';
import {
    generateWriteStateSlice,
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/ServerRequestStateSlices';
import { request } from '../AsyncRequest';
import { enforcementPost } from '../Http';
import { language, Language, texts } from '../i18n';
import { ResetPasswordForm } from './ResetPasswordForm';
import { slideInPortal, SlideInProps } from './SlideIn';
import { Spinner } from './Spinner';
import { ButtonContainer, TextButton } from './TextButton';
import { Colors, Typo } from './vars';

// MODEL

type ResetPasswordFailure =
    | CommunicationError
    | NoEmailForUser
    | NoEmailForAdminUser;

interface CommunicationError {
    kind: RemoteRequestFailureKind.COMMUNICATION_ERROR;
    httpStatus: number;
}

interface NoEmailForUser {
    kind: RemoteRequestFailureKind.NO_EMAIL_FOR_USER;
    organizationAdminName: string;
}

interface NoEmailForAdminUser {
    kind: RemoteRequestFailureKind.NO_EMAIL_FOR_ADMIN_USER;
}

enum RemoteRequestFailureKind {
    COMMUNICATION_ERROR,
    NO_EMAIL_FOR_USER,
    NO_EMAIL_FOR_ADMIN_USER,
}

export type ResetPasswordResponse =
    | ResponseOk
    | ResponseNoEmailForUser
    | ResponseNoEmailForAdminUser;

interface ResponseOk {
    code: ResponseCode.OK;
}

interface ResponseNoEmailForUser {
    code: ResponseCode.NO_EMAIL_FOR_USER;
    organizationAdminName: string;
}

interface ResponseNoEmailForAdminUser {
    code: ResponseCode.NO_EMAIL_FOR_ADMIN_USER;
}

enum ResponseCode {
    OK = 'OK',
    NO_EMAIL_FOR_USER = 'NO_EMAIL_FOR_USER',
    NO_EMAIL_FOR_ADMIN_USER = 'NO_EMAIL_FOR_ADMIN_USER',
}

// VIEW

function ResetPasswordController(props: Props): JSX.Element {
    switch (props.remoteRequest.status) {
        case RequestStatus.NEVER_EXECUTED:
            return (
                <ResetPasswordForm
                    suggestedUsername={props.suggestedUsername}
                    onCancel={props.onClose}
                    onSubmit={props.httpPostPasswordReset}
                />
            );

        case RequestStatus.PENDING:
            return <Spinner />;

        case RequestStatus.ERROR:
            return (
                <Failure
                    username={props.submittedForm.username}
                    failure={{
                        kind: RemoteRequestFailureKind.COMMUNICATION_ERROR,
                        httpStatus: props.remoteRequest.httpStatus,
                    }}
                    onClose={props.onClose}
                />
            );

        case RequestStatus.SUCCESS:
            if (props.remoteRequest.data.code === ResponseCode.OK) {
                return (
                    <Success
                        username={props.submittedForm.username}
                        onClose={props.onClose}
                    />
                );
            }

            return (
                <Failure
                    username={props.submittedForm.username}
                    failure={failure(props.remoteRequest.data)}
                    onClose={props.onClose}
                />
            );
    }
}

interface Props extends BaseProps {
    submittedForm: SubmittedFormState.State;
    remoteRequest: RemoteRequestState.State;
    httpPostPasswordReset: (username: string) => void;
}

interface BaseProps extends SlideInProps {
    suggestedUsername: string;
}

function failure(
    res: ResponseNoEmailForUser | ResponseNoEmailForAdminUser,
): ResetPasswordFailure {
    if (res.code === ResponseCode.NO_EMAIL_FOR_USER) {
        return {
            kind: RemoteRequestFailureKind.NO_EMAIL_FOR_USER,
            organizationAdminName: res.organizationAdminName,
        };
    }

    return { kind: RemoteRequestFailureKind.NO_EMAIL_FOR_ADMIN_USER };
}

namespace SubmittedFormState {
    export interface State {
        username: string;
    }

    export const { get, stateWrite } = generateState<State>(
        'reset-password-controller-submitted-form',
        { username: '' },
    );
}

namespace RemoteRequestState {
    export type State = ServerRequestState<ResetPasswordResponse>;

    export const { get, setResponse, reset } =
        generateWriteStateSlice<ResetPasswordResponse>({
            key: 'reset-password-controller-remote-request',
        });
}

const ConnectedResetPasswordController = selectState<
    BaseProps,
    {
        submittedForm: SubmittedFormState.State;
        remoteRequest: RemoteRequestState.State;
    }
>(
    store => ({
        submittedForm: SubmittedFormState.get(store),
        remoteRequest: RemoteRequestState.get(store),
    }),
    props => (
        <ResetPasswordController
            {...props}
            httpPostPasswordReset={makeHttpPostPasswordReset(props.update)}
        />
    ),
    {
        willUnmount(store: Store) {
            RemoteRequestState.reset(store);
        },
    },
);

function makeHttpPostPasswordReset(
    update: Updater,
): (username: string) => void {
    return username => {
        update(store => SubmittedFormState.stateWrite(store, { username }));

        update(
            request({
                req: (data?: {
                    username: string;
                    language: Language;
                    source: 'APP';
                }) => enforcementPost('/reset-password', data!),

                useAuthToken: false,
                requestWrite: (store, res): string => {
                    RemoteRequestState.setResponse(store, res);
                    return 'reset-password';
                },
            }),
            { username, language: language(), source: 'APP' },
        );
    };
}

export const ResetPasswordControllerSlideIn = slideInPortal<BaseProps>(
    ConnectedResetPasswordController,
);

function Failure(props: {
    username: string;
    failure: ResetPasswordFailure;
    onClose: () => void;
}) {
    return (
        <>
            <div className={errorStyle}>
                {failureMessage(props.username, props.failure)}
            </div>

            <Close onClick={props.onClose} />
        </>
    );
}

function failureMessage(
    username: string,
    failure: ResetPasswordFailure,
): string {
    switch (failure.kind) {
        case RemoteRequestFailureKind.COMMUNICATION_ERROR:
            return texts.passwordResetRequestFailed(failure.httpStatus);

        case RemoteRequestFailureKind.NO_EMAIL_FOR_USER:
            return texts.passwordResetRequestFailedNoEmailUser(
                username,
                failure.organizationAdminName,
            );

        case RemoteRequestFailureKind.NO_EMAIL_FOR_ADMIN_USER:
            return texts.passwordResetRequestFailedNoEmailAdmin(username);
    }
}

function Success(props: { username: string; onClose: () => void }) {
    return (
        <>
            <div className={messageStyle}>
                {texts.passwordResetRequestSucceeded(props.username)}
            </div>

            <Close onClick={props.onClose} />
        </>
    );
}

function Close(props: { onClick: () => void }) {
    return (
        <ButtonContainer>
            <TextButton onClick={props.onClick} label={texts.close} />
        </ButtonContainer>
    );
}

const messageStyle = css`
    ${Typo.robotoLight};
    font-size: 20px;
    font-weight: bold;
    margin: 60px 30px 24px 30px;
`;

const errorStyle = css`
    color: ${Colors.red};
    ${messageStyle};
`;
