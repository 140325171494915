import * as EnforcementState from '../state/EnforcementState';
import { Version } from '../state/EnforcementState';

import { useState } from 'react';
import { LoginSlideInTrigger } from './UserSlideIn';
import { Localized, texts } from '../i18n';
import { Updater, useStore } from 'dg-web-shared/lib/Flux';
import { css } from '@emotion/css';
import { Colors, Typo } from './vars';
import { slideInPortal, SlideInProps } from './SlideIn';

import icareLogo from './../../assets/icare.svg';

interface Props {
    layout: EnforcementState.Layout.State;
    username: string;
    authToken: string;
    update: Updater;
}

const Header = (p: { children?: React.ReactNode }): JSX.Element => {
    return (
        <div
            className={css({
                fontSize: '24px',
                marginBottom: '32px',
            })}
        >
            {p.children}
        </div>
    );
};

function hasAllCountries(version: Version.State): boolean {
    if (version.versionCode === null) {
        return false;
    }

    if (version.platform === 'android' && version.versionCode >= 33) {
        return true;
    } else if (
        version.platform === 'ios' &&
        version.release !== null &&
        parseFloat(version.release) >= 1.6
    ) {
        return true;
    }

    return false;
}

export const Instruction = (p: Props) => {
    const { storeState } = useStore(store => ({ version: Version.get(store) }));
    const [errorRecognitionSlideInOpen, setErrorRecognitionSlideInOpen] =
        useState(false);

    return (
        <div
            className={css({
                display: 'flex',
                flexDirection: 'column',
                color: Colors.darkBlue,
                overflow: 'auto',
            })}
        >
            <div
                className={css({
                    padding: '32px',
                    ...Typo.robotoRegular,
                    fontSize: '16px',
                    flexGrow: 1,
                })}
            >
                {p.layout.mode === 'lpr' && (
                    <div>
                        <Header>{texts.helpLprHeader}</Header>
                        <p>{texts.helpLprExpl}</p>
                        <p>{texts.helpLprSupportedCountries}</p>
                        {hasAllCountries(storeState.version) ? (
                            <ul
                                style={{
                                    columns: 1,
                                    listStyle: 'outside disc',
                                    lineHeight: 1.5,
                                }}
                            >
                                <li>
                                    <b>{texts.helpLprCH}</b>
                                </li>
                                <li>
                                    <b>{texts.helpLprFL}</b>
                                </li>
                                <li>
                                    <b>{texts.helpLprUeCountries}</b>
                                    <br />
                                    {texts.helpLprExcludedUeCountries}
                                </li>
                            </ul>
                        ) : (
                            <ul
                                style={{
                                    columns: 2,
                                    listStyle: 'inside disc',
                                }}
                            >
                                <li>{texts.helpLprCH}</li>
                                <li>{texts.helpLprDE}</li>
                                <li>{texts.helpLprFR}</li>
                                <li>{texts.helpLprIT}</li>
                                {<li>{texts.helpLprAT}</li>}
                                {<li>{texts.helpLprFL}</li>}
                            </ul>
                        )}
                        <p>
                            <Localized
                                de="Fehlererkennung: "
                                fr="Détection des erreurs: "
                                it="Identificazione errori: "
                            />
                            <a
                                className={css({
                                    textDecoration: 'underline',
                                })}
                                onClick={() =>
                                    setErrorRecognitionSlideInOpen(true)
                                }
                            >
                                <Localized
                                    de="Details anzeigen"
                                    fr="afficher les détails"
                                    it="mostra dettagli"
                                />
                            </a>
                            .
                        </p>
                        <img
                            style={{
                                float: 'right',
                                paddingTop: 8,
                                height: 24,
                                opacity: 0.8,
                            }}
                            src={icareLogo}
                        />
                    </div>
                )}

                {p.layout.mode === 'keyboard' && (
                    <div>
                        <Header>{texts.helpTextHeader}</Header>
                        <p>{texts.helpTextExpl}</p>
                    </div>
                )}
            </div>
            <LoginSlideInTrigger
                username={p.username}
                update={p.update}
                authToken={p.authToken}
            />
            <ErrorDetectionInfoSlideIn
                title={
                    <Localized
                        de="Fehlererkennung"
                        fr="Détection des erreurs"
                        it="Identificazione errori"
                    />
                }
                open={errorRecognitionSlideInOpen}
                onClose={() => setErrorRecognitionSlideInOpen(false)}
            />
        </div>
    );
};

const ErrorDetectionInfoSlideIn =
    slideInPortal<SlideInProps>(ErrorDetectionInfo);

function ErrorDetectionInfo() {
    return (
        <div
            className={css({
                padding: '23px',
                color: Colors.darkBlue,
                ...Typo.robotoRegular,
                fontSize: '16px',
            })}
        >
            <Localized
                de={
                    'Parkingcheck ist in der Lage die folgenden Fehler bei der Benutzereingabe zu identifizieren (höchstens einen Fehler auf einmal):'
                }
                fr={
                    "Parkingcheck est capables d'identifier les erreurs suivantes dans la saisie de l'utilisateur (au maximum un erreur à la fois):"
                }
                it={
                    "Parkingcheck é in grado di identificare i seguenti errori di immissione da parte dell'utente (al massimo un errore per volta):"
                }
            />
            <ul
                className={css({
                    '& li': { paddingBottom: '12px' },
                })}
            >
                <Localized
                    de={
                        <>
                            <li>
                                <b>Fehlen der Abkürzung des Kantons</b>
                                <br />
                                (z.B. 123456 statt <b>ZH</b>123456)
                            </li>
                            <li>
                                <b>
                                    Fehlen des ersten Buchstabens des
                                    Kantonskürzels
                                </b>
                                <br />
                                (z.B. <b>H</b>123456 statt <b>ZH</b>123456)
                            </li>
                            <li>
                                <b>Kantonskürzel am Ende</b>
                                <br />
                                (z.B. 123456<b>ZH</b> statt <b>ZH</b>123456)
                            </li>
                            <li>
                                <b>
                                    Die Ziffer 0 anstelle des Buchstabens O oder
                                    umgekehrt
                                </b>
                                <br />
                                (z.B. SG1432<b>O</b> anstelle von SG1432<b>0</b>
                                )
                            </li>
                            <li>
                                <b>
                                    Höchstens eine Zahl ist ein Wert höher oder
                                    niedriger als die richtige
                                </b>
                                <br />
                                (z.B. AG1<b>2</b>3456 statt AG1<b>3</b>3456)
                            </li>
                            <li>
                                <b>Höchstens zwei Zahlen sind vertauscht</b>
                                <br />
                                (z.B. BE<b>21</b>3456 statt BE<b>12</b>3456)
                            </li>
                            <li>
                                <b>Fehlen von Umlauten</b>
                                <br />
                                (z.B. S<b>A</b>KEL1F / S<b>AE</b>KEL1F statt S
                                <b>Ä</b>KEL1F)
                            </li>
                            <li>
                                <b>
                                    NEU: Fehlen des Präfixes in diplomatischen
                                    Kennzeichen
                                </b>
                                <br />
                                (z.B. BE1234 statt <b>CD</b>BE1234)
                            </li>
                        </>
                    }
                    fr={
                        <>
                            <li>
                                <b>Absence de l&apos;abréviation du canton</b>
                                <br />
                                (par exemple, 123456 au lieu de <b>VD</b>123456)
                            </li>
                            <li>
                                <b>
                                    Absence de la première lettre de
                                    l&apos;abréviation du canton
                                </b>
                                <br />
                                (par exemple, <b>D</b>123456 au lieu de{' '}
                                <b>VD</b>123456)
                            </li>
                            <li>
                                <b>L&apos;abréviation du canton à la fin</b>
                                <br />
                                (par exemple, 123456<b>VD</b> au lieu de{' '}
                                <b>VD</b>123456)
                            </li>
                            <li>
                                <b>
                                    Le chiffre 0 au lieu de la lettre O ou vice
                                    versa
                                </b>
                                <br />
                                (par exemple, FR1432<b>O</b> au lieu de FR1432
                                <b>0</b>)
                            </li>
                            <li>
                                <b>
                                    Un chiffre au maximum, a une valeur
                                    supérieure ou inférieure
                                </b>
                                <br />
                                (par exemple, VS1<b>2</b>3456 au lieu de VS1
                                <b>3</b>3456)
                            </li>
                            <li>
                                <b>Deux chiffres au maximum, sont inversés</b>
                                <br />
                                (par exemple, NE<b>21</b>3456 au lieu de NE
                                <b>12</b>3456)
                            </li>
                            <li>
                                <b>L&apos;absence de trémas</b>
                                <br />
                                (par exemple, S<b>A</b>KEL1F / S<b>AE</b>KEL1F
                                au lieu de S<b>Ä</b>KEL1F)
                            </li>
                            <li>
                                <b>
                                    NOUVEAU: absence du préfixe dans les plaques
                                    diplomatiques
                                </b>
                                <br />
                                (par exemple, GE1234 au lieu de <b>CD</b>GE1234)
                            </li>
                        </>
                    }
                    it={
                        <>
                            <li>
                                <b>mancanza della sigla del cantone</b>
                                <br />
                                (es. 123456 invece di <b>TI</b>123456)
                            </li>
                            <li>
                                <b>
                                    mancanza della prima lettera della sigla del
                                    cantone
                                </b>
                                <br />
                                (es. <b>I</b>123456 invece di <b>TI</b>123456)
                            </li>
                            <li>
                                <b>sigla del cantone alla fine</b>
                                <br />
                                (es. 123456<b>TI</b> invece di <b>TI</b>123456)
                            </li>
                            <li>
                                <b>
                                    la cifra 0 invece della lettera O o
                                    viceversa
                                </b>
                                <br />
                                (es. TI1432<b>O</b> invece di TI1432<b>0</b>)
                            </li>
                            <li>
                                <b>
                                    al massimo una cifra è di uno più alta o più
                                    bassa rispetto a quella giusta
                                </b>
                                <br />
                                (es. TI1<b>2</b>3456 invece di TI1<b>3</b>3456)
                            </li>
                            <li>
                                <b>al massimo due cifre sono invertite</b>
                                <br />
                                (es. TI<b>21</b>3456 invece di TI<b>12</b>3456)
                            </li>
                            <li>
                                <b>mancanza delle dieresi</b>
                                <br />
                                (es. S<b>A</b>KEL1F / S<b>AE</b>KEL1F invece di
                                S<b>Ä</b>KEL1F)
                            </li>
                            <li>
                                <b>
                                    NUOVO: mancanza prefisso nelle targhe
                                    diplomatiche
                                </b>
                                <br />
                                (es. TI1234 invece di <b>CD</b>TI1234)
                            </li>
                        </>
                    }
                />
            </ul>
            <Localized
                de={
                    <>
                        In all diesen Fällen{' '}
                        <b>enthält das Kontrollergebnis zwei Kennzeichen</b>:
                        das erste (in ROT) welches zu 100% mit dem gesuchten
                        übereinstimmt, während das zweite (in GRÜN) durch
                        Anwendung der Fehlerkorrektur gefunden wird.
                    </>
                }
                fr={
                    <>
                        Pour tous ces cas,{' '}
                        <b>le résultat contrôle contient deux résultats</b>: le
                        premier (en ROUGE) est celui de l&apos;immatriculation
                        recherchée, puis le second (en VERT) qui correspond à
                        l&apos;immatriculation trouvée en appliquant la
                        correction d&apos;erreur.
                    </>
                }
                it={
                    <>
                        In tutti questi casi{' '}
                        <b>il risultato del controllo contiene due targhe</b>:
                        la prima (in ROSSO) è quella che corrisponde al 100% con
                        quella cercata, mentre la seconda (in VERDE) è quella
                        trovata applicando la correzione degli errori.
                    </>
                }
            />
        </div>
    );
}
