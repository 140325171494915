import * as Flux from 'dg-web-shared/lib/Flux';
import * as EnforcementState from './EnforcementState';
import {
    EnforcementServerState,
    generateEnforcementContextualServerReadStateSlice,
} from './EnforcementStateSlices';
import { enforcementGet, Env } from '../Http';
import { Location } from 'dg-web-shared/common/utils/Geolocation';
import { GeolocationState } from './GeolocationState';
import { LocalStorageBase } from 'dg-web-shared/lib/LocalStorageBase';

export interface MasterData {
    zones: Zone[];
    userName: string;
    isAdmin: boolean;
    geolocation: boolean;
    rfidEnforcement: boolean;
}

export interface Zone {
    name: string;
    zipCode: string;
    city: string;
    id: number;
    extZoneCode: number;
}

export interface City {
    zipCode: string;
    city: string;
}

export const getCities = (zones: Zone[]): City[] => {
    const ref: { [idx: string]: { [idx2: string]: boolean } } = {};
    zones.forEach(z => {
        if (!ref[z.zipCode]) {
            ref[z.zipCode] = {};
        }
        ref[z.zipCode][z.city.trim()] = true;
    });
    return Object.keys(ref).map(zipCode => {
        return { zipCode, city: Object.keys(ref[zipCode]).join(', ') };
    });
};

export const getZonesOfCity = (
    zipCode: string | null,
    allZones: Zone[],
): Zone[] => {
    return allZones.filter(z => z.zipCode === zipCode);
};

export type State = EnforcementServerState<MasterData | null>;

export const { get, refetchSameContext } =
    generateEnforcementContextualServerReadStateSlice<
        { env: Env; location: Location | null; twintPairing: boolean },
        MasterData | null
    >({
        key: 'MasterDataState',
        req: (args: {
            env: Env;
            location: Location | null;
            twintPairing: boolean;
        }) => {
            const locQuery =
                `?twint-pairing=${args.twintPairing}` +
                (args.location &&
                args.location.longitude &&
                args.location.latitude
                    ? `&lon=${args.location.longitude}&lat=${args.location.latitude}`
                    : '');
            return enforcementGet(`/master-data${locQuery}`, args.env);
        },
        onResponse: (store: Flux.Store, state: State): void => {
            // since android always selects the first entry in a dropdown, set the state accordingly
            if (
                state.status === 'success' &&
                state.data &&
                state.data.zones.length > 0
            ) {
                const selectedZoneId = EnforcementState.Form.get(store).zoneId;
                if (
                    selectedZoneId == null ||
                    state.data.zones.filter(z => z.id === selectedZoneId)
                        .length === 0
                ) {
                    const firstZone = state.data.zones[0];
                    EnforcementState.Form.stateWrite(store, {
                        zipCode: firstZone.zipCode,
                        zoneId: firstZone.id,
                    });
                }

                const isIosUrl =
                    window.location.href.indexOf('platform=ios') > -1;
                if (isIosUrl) {
                    LocalStorageBase.setStringItem('isIos', 'true');
                }
                const isIOs =
                    isIosUrl ||
                    LocalStorageBase.getStringItem('isIos') === 'true';

                if (state.data.geolocation && !isIOs) {
                    GeolocationState.stateWrite(store, { enabled: true });
                }
            }
        },
        refreshEverySecs: 60 * 60 * 24,
    });
