import { TextField } from './TextField';
import { texts } from '../i18n';
import { Colors } from './vars';
import { css } from '@emotion/css';
import { ButtonContainer, TextButton } from './TextButton';
import React from 'react';

export class ResetPasswordForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.handleEnter = this.handleEnter.bind(this);

        this.state = {
            username: props.suggestedUsername,
            usernameEdited: false,
        };
    }

    render() {
        return (
            <div className={style}>
                <TextField
                    inputType="text"
                    label={texts.username}
                    value={this.state.username}
                    onChange={(username: string) => {
                        this.setState({ username });
                    }}
                    onBlur={() => this.setState({ usernameEdited: true })}
                    onEnter={this.handleEnter}
                    errorText={this.errorText()}
                />

                <div className={css({ marginTop: '36px' })}>
                    <ButtonContainer>
                        <TextButton
                            onClick={this.props.onCancel}
                            label={texts.cancel}
                        />

                        <TextButton
                            onClick={() =>
                                this.props.onSubmit(this.state.username)
                            }
                            label={texts.submit}
                            disabled={!this.isValid()}
                        />
                    </ButtonContainer>
                </div>
            </div>
        );
    }

    private handleEnter(): void {
        if (this.isValid()) {
            this.props.onSubmit(this.state.username);
        }
    }

    private errorText(): string {
        return this.state.usernameEdited && !this.isValid()
            ? texts.requiredField
            : '';
    }

    private isValid(): boolean {
        return this.state.username.length > 0;
    }
}

interface Props {
    suggestedUsername: string;
    onCancel: () => void;
    onSubmit: (username: string) => void;
}

interface State {
    username: string;
    usernameEdited: boolean;
}

const style = css`
    margin: 24px 10px;
    padding: 0px 40px;
    & [data-error='true'] {
        color: ${Colors.red};
    }
`;
