import * as superagent from 'superagent';

/**
 * @deprecated
 */
export type LegacyRequest = superagent.Request<unknown, unknown>;
export type Env = 'prod' | 'test' | 'demo';
/**
 * @deprecated
 */
export const makeEnforcementUrl = (path: string, env: Env): string => {
    let url = '';
    if (env === 'test') {
        url = 'https://api-test.digitalparking.ch';
    }
    if (env === 'demo') {
        url = 'https://api-demo.digitalparking.ch';
    }
    return `${url}/enforcement/v2/${path}`;
};

/**
 * @deprecated
 */
export const enforcementGet = (path: string, env: Env): LegacyRequest => {
    return superagent.get(makeEnforcementUrl(normalizePath(path), env));
};

/**
 * @deprecated
 */
export const enforcementPut = (
    path: string,
    payload: object,
): LegacyRequest => {
    return superagent
        .put(makeEnforcementUrl(normalizePath(path), 'prod'))
        .send(payload);
};

/**
 * @deprecated
 */
export const enforcementPost = (
    path: string,
    payload: object,
): LegacyRequest => {
    return superagent
        .post(makeEnforcementUrl(normalizePath(path), 'prod'))
        .send(payload);
};

/**
 * @deprecated
 */
function normalizePath(path: string): string {
    return path[0] === '/' ? (path = path.substring(1)) : path;
}
