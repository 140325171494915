import { css } from '@emotion/css';
import { texts } from '../i18n';
import { TextField } from './TextField';
import { Colors } from './vars';
import { ButtonContainer, TextButton } from './TextButton';
import React from 'react';

export class ChangePasswordForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.handleEnter = this.handleEnter.bind(this);
        this._promiseSetStateDone = Promise.resolve();
        this.state = {
            password: '',
            passwordEdited: false,
            confirmation: '',
        };
    }

    private _promiseSetStateDone: Promise<void>;

    get promiseSetStateDone() {
        return this._promiseSetStateDone;
    }

    render() {
        return (
            <div className={style}>
                <div>
                    <TextField
                        data-test="password"
                        inputType="password"
                        label={texts.newPassword}
                        value={this.state.password}
                        onChange={(password: string) => {
                            this._promiseSetStateDone = new Promise(resolve => {
                                this.setState({ password }, resolve);
                            });
                        }}
                        onBlur={() => {
                            this._promiseSetStateDone = new Promise(resolve => {
                                this.setState(
                                    { passwordEdited: true },
                                    resolve,
                                );
                            });
                        }}
                        onEnter={this.handleEnter}
                        errorText={this.passwordErrorText()}
                    />
                </div>
                <div>
                    <TextField
                        data-test="confirmation"
                        inputType="password"
                        label={texts.confirmPassword}
                        value={this.state.confirmation}
                        onChange={(confirmation: string) => {
                            this._promiseSetStateDone = new Promise(resolve => {
                                this.setState({ confirmation }, resolve);
                            });
                        }}
                        onEnter={this.handleEnter}
                        errorText={this.confirmationErrorText()}
                    />
                </div>

                <div className={css({ marginTop: '36px' })}>
                    <ButtonContainer>
                        <TextButton
                            data-test="cancel"
                            onClick={this.props.onCancel}
                            label={texts.cancel}
                        />

                        <TextButton
                            data-test="save"
                            onClick={() =>
                                this.props.onSave(this.state.password)
                            }
                            label={texts.save}
                            disabled={
                                validate(
                                    this.state.password,
                                    this.state.confirmation,
                                ) !== ValidationResult.OK
                            }
                        />
                    </ButtonContainer>
                </div>
            </div>
        );
    }

    private handleEnter(): void {
        if (
            validate(this.state.password, this.state.confirmation) ===
            ValidationResult.OK
        ) {
            this.props.onSave(this.state.password);
        }
    }

    private passwordErrorText(): string {
        if (!this.state.passwordEdited) {
            return '';
        }

        switch (validate(this.state.password, this.state.confirmation)) {
            case ValidationResult.ERROR_EMPTY:
                return texts.requiredField;

            case ValidationResult.ERROR_TOO_SHORT:
                return texts.passwordIsTooShort(MIN_PASSWORD_LENGTH);

            default:
                return '';
        }
    }

    private confirmationErrorText(): string {
        if (
            !this.state.passwordEdited ||
            this.state.confirmation.length < this.state.password.length
        ) {
            return '';
        }

        if (
            validate(this.state.password, this.state.confirmation) ===
            ValidationResult.ERROR_CONFIRMATION_MISMATCH
        ) {
            return texts.passwordFieldsDoNotMatch;
        }

        return '';
    }
}

export interface Props {
    onCancel: () => void;
    onSave: (newPassword: string) => void;
}

export interface State {
    password: string;
    passwordEdited: boolean;
    confirmation: string;
}

const style = css`
    margin: 24px 10px;
    padding: 0px 40px;
    & [data-error='true'] {
        color: ${Colors.red};
    }
`;

export function validate(
    password: string,
    confirmation: string,
): ValidationResult {
    if (password === '') {
        return ValidationResult.ERROR_EMPTY;
    }

    if (password.length < MIN_PASSWORD_LENGTH) {
        return ValidationResult.ERROR_TOO_SHORT;
    }

    if (password !== confirmation) {
        return ValidationResult.ERROR_CONFIRMATION_MISMATCH;
    }

    return ValidationResult.OK;
}

export enum ValidationResult {
    OK,
    ERROR_EMPTY,
    ERROR_TOO_SHORT,
    ERROR_CONFIRMATION_MISMATCH,
}

export const MIN_PASSWORD_LENGTH = 5;
