import * as Flux from 'dg-web-shared/lib/Flux';
import { generateState } from 'dg-web-shared/lib/Flux';
import * as AuthTokenState from './AuthTokenState';
import * as LocalStorage from 'dg-web-shared/lib/LocalStorage';
import { enforcementGet, Env, LegacyRequest } from '../Http';
import {
    EnforcementServerState,
    generateEnforcementContextualServerReadStateSlice,
} from './EnforcementStateSlices';
import { language } from '../i18n';
import { Location } from 'dg-web-shared/common/utils/Geolocation';
import { GeolocationState } from './GeolocationState';
import { EnforcedLicensePlate } from 'enforcement-result/src/EnforcementTypes.ts';

export type EnforcementMode = 'lpr' | 'keyboard' | 'twint-qr-code';

export namespace Layout {
    export interface State {
        mode: EnforcementMode;
        allowedModes: EnforcementMode[];
    }

    export const { get, reset, stateWrite } = Flux.generateState<State>(
        'EnforcementState-Layout',
        {
            mode: 'keyboard',
            allowedModes: ['keyboard', 'twint-qr-code'],
        },
    );
}

export namespace Version {
    export interface State {
        platform: null | 'android' | 'ios';
        versionCode: number | null;
        release: string | null;
        sdk: string | null;
        manufacturer: string | null;
        model: string | null;
    }

    export const { get, reset, stateWrite } = generateState<State>(
        'EnforcementState-Version',
        {
            platform: null,
            versionCode: null,
            release: null,
            sdk: null,
            manufacturer: null,
            model: null,
        },
    );

    export function clientVersionString(version: State): string {
        const versionFields = [
            version.platform,
            version.versionCode,
            version.release,
            version.sdk,
            version.manufacturer,
            version.model,
        ];

        return `${SENTRY_RELEASE} ${versionFields
            .filter(v => v != null)
            .join('_')}`;
    }
}

export namespace Form {
    export interface State {
        zipCode: string | null;
        zoneId: number | null;
        searchString: string | null;
    }

    const s = Flux.generateState<State>('EnforcementState-Form', () => {
        const s = LocalStorage.getSerializableItem(
            LocalStorage.Items.enforcementSelectionState,
        ) as State;
        if (s) {
            return s;
        }
        return {
            zoneId: null,
            zipCode: null,
            searchString: null,
        };
    });

    export const get = s.get;
    export const reset = s.reset;
    export const stateWrite = (
        store: Flux.Store,
        state: Partial<State>,
    ): string => {
        const name = s.stateWrite(store, state);
        LocalStorage.setSerializableItem(
            LocalStorage.Items.enforcementSelectionState,
            s.get(store),
        );
        return name;
    };
}

export namespace Context {
    export interface State {
        zoneId: number;
        searchString: string;
        enforceId: number;
        env: Env;
        mode: string | null;
        location: Location | null;
    }

    interface InternalState {
        zoneId: number | null;
        searchString: string | null;
        enforceId: number | null;
        location: Location | null;
    }

    export const s = Flux.generateState<InternalState>(
        'EnforcementState-Context',
        (): InternalState => {
            return {
                zoneId: null,
                searchString: null,
                enforceId: null,
                location: null,
            };
        },
    );

    export const reset = s.reset;

    export const get = (store: Flux.Store): State | null => {
        const state = s.get(store);
        const env = AuthTokenState.get(store).env;
        const mode = Layout.get(store).mode;
        if (state.zoneId && state.searchString && state.enforceId) {
            return {
                zoneId: state.zoneId,
                searchString: state.searchString,
                enforceId: state.enforceId,
                env: env,
                mode: mode,
                location: state.location,
            };
        } else {
            return null;
        }
    };

    export const writeFormState = (
        store: Flux.Store,
        formState: Form.State,
    ): string => {
        return s.stateWrite(store, {
            zoneId: formState.zoneId,
            searchString: formState.searchString,
            enforceId: Date.now(),
            location: GeolocationState.get(store).currentLocation,
        });
    };
}

export const formIsValid = (state: Form.State): boolean =>
    !!state.zoneId && !!state.zoneId && !!state.searchString;

export namespace ResultNamespace {
    import clientVersionString = Version.clientVersionString;
    export type State = EnforcementServerState<EnforcedLicensePlate[]>;
    export type EnforcementResultArgs = {
        zoneId: number;
        searchString: string;
        mode: string | null;
        enforceId: number;
        env: Env;
        location: Location | null;
        version: Version.State;
    };
    export const { get } = generateEnforcementContextualServerReadStateSlice<
        EnforcementResultArgs,
        EnforcedLicensePlate[]
    >({
        key: 'EnforcementState-result',
        req: (args: EnforcementResultArgs): LegacyRequest => {
            const query: any = {
                zone: args.zoneId,
                language: language(),
                recognitionType: args.mode,
                clientName: 'Parkingcheck',
                clientVersion: clientVersionString(args.version),
            };
            if (args.location !== null) {
                query.lat = args.location.latitude;
                query.lon = args.location.longitude;
            }
            if (
                args.searchString.length === 12 &&
                /^\d+$/.test(args.searchString)
            ) {
                query.vignetteLabelNr = args.searchString;
            } else {
                query.licensePlateNumber = args.searchString;
            }

            query.version = btoa(JSON.stringify(args.version));

            return enforcementGet('/enforcement-result', args.env).query(
                query,
            ) as LegacyRequest;
        },
        onResponse: (store: Flux.Store, state: State): void => {
            if (
                state.status === 'success' &&
                Layout.get(store).mode !== 'lpr'
            ) {
                Form.stateWrite(store, { searchString: '' });
            }
        },
    });
}
