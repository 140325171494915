import { css } from '@emotion/css';
import { texts } from '../i18n';
import { Store, Updater } from 'dg-web-shared/lib/Flux';
import { slideInPortal, SlideInProps } from './SlideIn';
import { ButtonContainer, TextButton } from './TextButton';
import { Colors, Typo } from './vars';

export const UserActionsSlideIn = slideInPortal<Props>(UserActions);

export function UserActions(props: Props) {
    return (
        <div className={style}>
            <div className={userStyle}>
                <div className={userCaptionStyle}>{texts.username}</div>
                <div className={userUsernameStyle}>{props.username}</div>
            </div>
            <div className={buttonStyle}>
                <ButtonContainer>
                    <TextButton
                        data-test="change-password"
                        label={texts.changePassword}
                        onClick={props.onChangePassword}
                    />
                </ButtonContainer>
                <ButtonContainer>
                    <TextButton
                        label={texts.logout}
                        onClick={() => props.update(props.logout)}
                    />
                </ButtonContainer>
            </div>
        </div>
    );
}

interface Props extends SlideInProps {
    update: Updater;
    username: string;
    logout: (store: Store) => string;
    onChangePassword: () => void;
}

const style = css`
    display: flex;
    flex-direction: column;
    height: 100%;
    background: ${Colors.greyBackground};
`;

const userStyle = css`
    flex: 1 1 auto;
    color: ${Colors.darkBlue};
`;

const userCaptionStyle = css`
    margin-top: 56px;
    text-align: center;
    ${Typo.robotoRegular};
`;

const userUsernameStyle = css`
    text-align: center;
    ${Typo.robotoLight};
    font-size: 18px;
    letter-spacing: 0.04em;
`;

const buttonStyle = css`
    flex: 1 1 auto;
`;
