import { localState, Store, Updater } from 'dg-web-shared/lib/Flux';
import * as LocalStorage from 'dg-web-shared/lib/LocalStorage';
import { Clickable } from 'dg-web-shared/ui/Clickable';
import { texts } from '../i18n';
import { getBridge } from '../Native';
import * as AuthTokenState from '../state/AuthTokenState';
import * as EnforcementState from '../state/EnforcementState';
import { ChangePasswordSlideIn } from './ChangePasswordController';
import { UserActionsSlideIn } from './UserActions';
import { css } from '@emotion/css';
import { Colors, Typo } from './vars';
import { getDisclaimerItems, saveDisclaimerItems } from '../LprDisclaimerFlags';

import logo from './../../assets/logo-icon_inverse.svg';

enum Views {
    HOME,
    ACTIONS,
    CHANGE_PASSWORD,
}

export const LoginSlideInTrigger = localState<
    {
        update: Updater;
        username: string;
        authToken: string;
    },
    { currentView: Views }
>({ currentView: Views.HOME }, p => {
    return (
        <div>
            <Clickable
                element="div"
                className={css({
                    ...Typo.robotoRegular,
                    fontSize: '14px',
                    marginLeft: '16px',
                    marginBottom: '16px',
                    color: Colors.darkBlue,
                })}
                onClick={() => p.setState({ currentView: Views.ACTIONS })}
            >
                <img
                    className={css({
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        width: '16px',
                        height: '16px',
                        marginRight: '8px',
                    })}
                    src={logo}
                />{' '}
                {p.username}
            </Clickable>
            <UserActionsSlideIn
                title=""
                open={p.state.currentView === Views.ACTIONS}
                onClose={() => p.setState({ currentView: Views.HOME })}
                update={p.update}
                username={p.username}
                logout={logout}
                onChangePassword={() =>
                    p.setState({ currentView: Views.CHANGE_PASSWORD })
                }
            />
            <ChangePasswordSlideIn
                title={texts.changePassword}
                open={p.state.currentView === Views.CHANGE_PASSWORD}
                onClose={() => p.setState({ currentView: Views.HOME })}
            />
        </div>
    );
});

export const logout = (store: Store): string => {
    const bridge = getBridge();
    if (bridge) {
        bridge.disableLPR();
    }
    // the disclaimer flags are bound to the device, therefore
    // they must be preserved to ensure the correct 1-time behavior
    const disclaimerItems = getDisclaimerItems();
    LocalStorage.clear();
    saveDisclaimerItems(disclaimerItems);

    EnforcementState.Form.reset(store);
    EnforcementState.Context.reset(store);
    AuthTokenState.reset(store);
    window.location.reload();
    return 'logout';
};
