import { Clickable, ClickHandler } from 'dg-web-shared/ui/Clickable';
import { css } from '@emotion/css';
import { Colors, Typo } from './vars';

interface Props {
    onClick: ClickHandler;
    label: string;
    disabled?: boolean;
}

export const ButtonContainer = (p: { children?: React.ReactNode }) => {
    return (
        <div
            className={css({
                display: 'flex',
                justifyContent: 'center',
                margin: '8px 0',
            })}
        >
            {p.children}
        </div>
    );
};

export const TextButton = (p: Props) => {
    const isDisabled = p.disabled ?? false;
    return (
        <Clickable
            element={'button'}
            className={css({
                background: 'none',
                border: 0,
                lineHeight: 'normal',
                overflow: 'visible',
                outline: 'none',
                height: '40px',
                padding: '0 15px',
                borderRadius: '2px',
                ...Typo.robotoMedium,
                letterSpacing: '0.04em',
                fontSize: '20px',
                textTransform: 'uppercase',
                color: isDisabled ? Colors.grey : Colors.lightBlue,
                opacity: isDisabled ? 0.6 : 1,
            })}
            onClick={p.onClick}
            disabled={isDisabled}
        >
            {p.label}
        </Clickable>
    );
};
