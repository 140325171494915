import * as Flux from 'dg-web-shared/lib/Flux';
import * as WriteStateSlice from './WriteStateSlice';

export namespace Form {
    export interface State {
        username: string;
        password: string;
        specialEnv: 'test' | 'demo' | null;
    }
    const s = Flux.generateState<State>('LoginState-Form', {
        username: '',
        password: '',
        specialEnv: null,
    });

    export const get = s.get;
    export const stateWrite = (
        store: Flux.Store,
        state: Partial<State>,
    ): string => {
        if (state.username && ['demo', 'test'].indexOf(state.username) > -1) {
            return s.stateWrite(store, {
                specialEnv: state.username as 'test' | 'demo',
                username: state.username === 'demo' ? 'demo' : '',
            });
        } else {
            return s.stateWrite(store, state);
        }
    };
}

export namespace Request {
    export type State = WriteStateSlice.State<void>;
    export const { get, reset, setResponse } = WriteStateSlice.generate(
        'LoginState-LoginRequest',
        (body: any) => body,
    );
}
