import React from 'react';
import { texts } from '../i18n';
import { ForgottenPasswordLink } from './ForgottenPasswordLink';
import { ResetPasswordControllerSlideIn } from './ResetPasswordController';

enum Views {
    HOME,
    RESET_PASSWORD_FORM,
}

export class ResetPasswordDeck extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { currentView: Views.HOME };
    }

    render() {
        return (
            <div>
                <ForgottenPasswordLink
                    onClick={() => {
                        this.setState({
                            currentView: Views.RESET_PASSWORD_FORM,
                        });
                    }}
                />

                <ResetPasswordControllerSlideIn
                    title={texts.resetPassword}
                    open={this.state.currentView === Views.RESET_PASSWORD_FORM}
                    onClose={() => {
                        this.setState({ currentView: Views.HOME });
                    }}
                    suggestedUsername={this.props.suggestedUsername}
                />
            </div>
        );
    }
}

interface Props {
    suggestedUsername: string;
}

interface State {
    currentView: Views;
}
