import { Maybe } from 'dg-web-shared/lib/MaybeV2';

interface NativeBridge {
    enableLPR: () => void;
    disableLPR: () => void;
    requestDeviceInfo?: () => void;
    requestDeviceInfoV2?: () => void;
}

export const getBridge = (): Maybe<NativeBridge> => {
    const w: any = <any>window;
    if (w.android) {
        return w.android;
    } else if (w.webkit && w.webkit.messageHandlers) {
        return {
            enableLPR: () =>
                w.webkit.messageHandlers.enableLPR.postMessage(null),
            disableLPR: () =>
                w.webkit.messageHandlers.disableLPR.postMessage(null),
            requestDeviceInfo:
                w.webkit.messageHandlers.requestDeviceInfo &&
                (() =>
                    w.webkit.messageHandlers.requestDeviceInfo.postMessage(
                        null,
                    )),
            requestDeviceInfoV2:
                w.webkit.messageHandlers.requestDeviceInfoV2 &&
                (() =>
                    w.webkit.messageHandlers.requestDeviceInfoV2.postMessage(
                        null,
                    )),
        };
    } else {
        return null;
    }
};
